import Toastify from 'toastify-js';

import "toastify-js/src/toastify.css";
import {theme} from '../../../tailwind.config';

export default {
  install(Vue) {
    Vue.prototype.$toastify = {
      success(config) {
        config = this.config(config);
        config.backgroundColor = theme.colors.success[100];
        return this.toast(config);
      },
      warning(config) {
        config = this.config(config);
        config.backgroundColor = theme.colors.warning[100];
        return this.toast(config);
      },
      info(config) {
        config = this.config(config);
        config.backgroundColor = theme.colors.secondary[100];
        return this.toast(config);
      },
      error(config) {
        config = this.config(config);
        config.backgroundColor = theme.colors.error[100];
        return this.toast(config);
      },
      config(config) {
        if ('string' === typeof config) {
          config = {
            text: config
          };
        }

        return config;
      },
      toast(config) {
        if (!config.duration) {
          config.duration = 3000;
        }

        return Toastify(config).showToast();
      }
    };
  }
}
