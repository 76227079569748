<template>
  <component class="rounded-xl flex items-center leading-none" :class="className" :is="component">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="icon" v-bind:svg-inline="''" v-bind:class="'mr-2 fill-current'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.375 9.688A9.686 9.686 0 009.687 0 9.686 9.686 0 000 9.688a9.686 9.686 0 009.688 9.687 9.686 9.686 0 009.687-9.688zM13.75 8.594a.932.932 0 011.328-.04l.43.43a.9.9 0 010 1.329l-5.195 5.195a.92.92 0 01-1.29 0l-5.195-5.195c-.351-.352-.351-.938 0-1.329l.43-.43a.932.932 0 011.328.04l2.851 2.93V4.374c0-.508.391-.938.938-.938H10a.95.95 0 01.938.938v7.148l2.812-2.93z"/></svg>
    <template v-else><span class="h-5"></span></template>  
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    component: {
      type: String,
      default: 'a'
    },
    icon: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: 'text-sm py-2 px-4 sm:text-base sm:py-3 sm:px-5 md:text-lg md:py-4 md:px-6'
    }
  }
}
</script>
