<template>
  <form @submit.prevent="send">
    <form-field class="mb-4" label="Nombre completo" v-model="name" name="name" id="name" type="text" v-validate="{required: true}" />
    <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row -mx-4 mb-4">
      <div class="px-4 w-full sm:w-1/2 md:w-full lg:w-1/2">
        <form-field class="mb-4 sm:mb-0 md:mb-4 lg:mb-0" label="Correo Electrónico" v-model="email" name="email" id="email" type="email" v-validate="{required: true, email: true}" />
      </div>
      <div class="px-4 w-full sm:w-1/2 md:w-full lg:w-1/2">
        <form-field label="Teléfono" v-model="phone" name="phone" id="phone" type="tel" v-validate="{required: true, numeric: true}" />
      </div>
    </div>
    <form-field class="mb-12" label="Mensaje" v-model="message" name="message" id="message" type="textarea" v-validate="{required: true}" />
    <base-button class-name="text-lg py-4 px-6" class="font-bold bg-brand-80 text-white w-full justify-center outline-none focus:outline-none" type="submit" component="button" :icon="false">{{loading?'Enviando':'Enviar mensaje'}}</base-button>
  </form>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        name: '',
        phone: '',
        email: '',
        message: '',
      }
    },
    methods: {
      async send() {
        var valid = await this.$validator.validate();

        ga && ga('send', 'event', 'ContactForm', 'send');

        if (!valid || this.loading) {
          return;
        }

        try {
          await this.$http.post('/mailer/submit-contact-form.php', {
            email: this.email,
            phone: this.phone,
            name: this.name,
            message: this.message,
          });

          this.$toastify.success('Formulario enviado con éxito');

          ga && ga('send', 'event', 'ContactForm', 'submitted');
        } catch (e) {
          console.error(e);
          this.$toastify.error('Encontramos un error al enviar el formulario, por favor intenta de nuevo');
        } finally {
          this.loading = false;
        }
      }
    }
  }
</script>
