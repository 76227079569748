import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import axios from 'axios';

Vue.use(VueScrollTo, {
  offset: function () {
    if (app) {
      return (app.$refs.header.$el.clientHeight - 10) * -1;
    }

    return -100;
  }
});

Vue.use(require('./components').default);
Vue.use(require('./components/icons').default);
Vue.use(require('./plugins/http').default, axios);
Vue.use(require('./plugins/validator').default);
Vue.use(require('./plugins/toastify').default);

const app = new Vue({
  el: '#app',
  methods: {
    track(section) {
      ga && ga('send', 'event', 'Navigation', section);
      ga && ga('send', 'pageview', section);

      setTimeout(() => {
        this.$refs.header && (this.$refs.header.open = false);
      }, 100)
    },
    button(location, app) {
      ga && ga('send', 'event', `${location}AppButton`, app);
    },
    download(location) {
      ga && ga('send', 'event', `${location}DownloadButton`);
    },
    more(location) {
      ga && ga('send', 'event', `${location}MoreButton`);
    }
  }
});
