<template>
  <div class="relative">
    <div class="absolute right-0 top-0 
      mt-4 mr-16">
      <img
        :style="policyStyle"
        src="@img/polizas@2x.png" 
        width="163" height="182" 
        alt="" 
        class="sm-down:w-32 md:max-w-none">
    </div>
    <div class="absolute top-0 left-0
      mt-48 -ml-32">
      <img 
        :style="policyStyle"
        src="@img/productos@2x.png" 
        width="181" height="202" 
        alt="" 
        class="sm-down:w-32 md:max-w-none">
    </div>
    <div class="absolute top-0 left-0
    -ml-24 -mt-6">
      <img
        :style="screenStyle" 
        src="@img/start-bg@2x.png" 
        width="525" height="680" 
        alt="" 
        class="md:max-w-none mx-auto">
    </div>
    <div class="absolute top-0 right-0
    mt-64">
      <img 
        :style="policyStyle"
        src="@img/mi-sisa-go@2x.png" 
        width="198" height="222" 
        alt="" 
        class="sm-down:w-32 md:max-w-none">
    </div>
    <div class="absolute bottom-0 left-0 
      -ml-24 mb-20">
      <img :style="kmStyle" 
        src="@img/autoxkm-2@2x.png" 
        width="274" 
        height="124" 
        alt=""
        class="md:max-w-none">
    </div>
  </div> 
</template>

<script>
import HasGlider from '@/mixins/hasGlider';

export default {
  mixins: [HasGlider],
  computed: {
    screenStyle() {
      const distanceX = 30 * this.xPos * -1;
      const distanceY = 20 * this.yPos;
      return {
        transform: `translateX(${distanceX}px) translateY(${distanceY}px)`
      }
    },
    policyStyle() {
      const distanceX = 20 * this.xPos;
      const distanceY = 10 * this.yPos * -1;
      return {
        transform: `translateX(${distanceX}px) translateY(${distanceY}px)`
      }
    },
    kmStyle() {
      const distanceX = 10 * this.xPos;
      const distanceY = 10 * this.yPos;
      return {
        transform: `translateX(${distanceX}px) translateY(${distanceY}px)`
      }
    }
  }
}
</script>
