export default {
  install(Vue) {
    Vue.component('the-header', require('./layout/header/TheHeader.vue').default);
    Vue.component('contact-form', require('./contact/ContactForm.vue').default);
    Vue.component('base-button', require('./ui/BaseButton.vue').default);
    Vue.component('form-field', require('./ui/FormField.vue').default);
    Vue.component('hero-glider', require('./hero/HeroGlider.vue').default);
    Vue.component('features-glider', require('./hero/FeaturesGlider.vue').default);
    Vue.component('quickstart-glider', require('./hero/QuickStartGlider.vue').default);
  }
}
