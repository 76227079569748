import VueAxios from 'vue-axios';

export default {
  install(Vue, axios) {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    // define Vue.axios required for vue-auth
    Vue.use(VueAxios, axios);
  }
}
