import { Validator, install } from 'vee-validate/dist/vee-validate.minimal';
import { required, email, numeric } from 'vee-validate/dist/rules.esm';

export default {
  async install(Vue) {
    Validator.extend('email', email);
    Validator.extend('required', required);
    Validator.extend('numeric', numeric);

    var messages = {
      _default: function(e) {
        return "Campo inválido."
      },
      email: function(e) {
        return "Ingresa un correo válido."
      },
      required: function(e) {
        return "Ingresa esta información."
      },
      numeric: function(e) {
        return "Ingresa un número."
      }
    };

    Vue.use({install}, {
      locale: 'es',
      dictionary: {
        es: { messages }
      }
    });
  }
}
