export default {
  install(Vue) {
    Vue.component('app-icon', require('./AppIcon.vue').default);
    Vue.component('icon-facebook', require('./FacebookIcon.vue').default);
    Vue.component('icon-twitter', require('./TwitterIcon.vue').default);
    Vue.component('icon-instagram', require('./InstagramIcon.vue').default);
    Vue.component('logo-white', require('./LogoWhite.vue').default);
    Vue.component('logo-blue', require('./LogoBlue.vue').default);
    Vue.component('logo-xkm', require('./LogoXKm.vue').default);
    Vue.component('benefit-001', require('./Benefit001.vue').default);
    Vue.component('benefit-002', require('./Benefit002.vue').default);
    Vue.component('benefit-003', require('./Benefit003.vue').default);
    Vue.component('benefit-004', require('./Benefit004.vue').default);
    Vue.component('benefit-005', require('./Benefit005.vue').default);
    Vue.component('benefit-006', require('./Benefit006.vue').default);
    Vue.component('benefit-007', require('./Benefit007.vue').default);
    Vue.component('benefit-008', require('./Benefit008.vue').default);
    Vue.component('benefit-009', require('./Benefit009.vue').default);
    Vue.component('button-app-store', require('./ButtonAppStore.vue').default);
    Vue.component('button-app-store-light', require('./ButtonAppStoreLight.vue').default);
    Vue.component('button-play-store', require('./ButtonPlayStore.vue').default);
    Vue.component('button-play-store-light', require('./ButtonPlayStoreLight.vue').default);
    Vue.component('icon-car', require('./IconCar.vue').default);
    Vue.component('icon-gauge', require('./IconGauge.vue').default);
    Vue.component('icon-money', require('./IconMoney.vue').default);
    Vue.component('icon-policy', require('./IconPolicy.vue').default);
    Vue.component('icon-products', require('./IconProducts.vue').default);
    Vue.component('icon-apple', require('./IconApple.vue').default);
    Vue.component('icon-google-play', require('./IconGooglePlay.vue').default);
    Vue.component('sisa-go', require('./SisaGo.vue').default);
  }
}
