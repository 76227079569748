export default {
  data() {
    return {
      xPos: 0,
      yPos: 0
    }
  },
  mounted() {
    try {
      document.createEvent("TouchEvent");
    } catch (e) {
      window.addEventListener(
        'mousemove',
        this.onMouseMove,
        false
      );
    }
  },
  destroyed() {
    window.removeEventListener(
      'mousemove',
      this.onMouseMove
    );
  },
  methods: {
    onMouseMove(e) {
      const {screenX:x, screenY:y} = e;

      const halfWidth = (window.innerWidth / 2);
      const halfHeight = (window.innerHeight / 2);

      this.xPos = (x - halfWidth) / halfWidth;
      this.yPos = (y - halfHeight) / halfHeight;
    }
  }
}
