<template>
  <div class="relative">
    <div class="
    md:absolute top-0 left-0 
    sm-down:max-w-lg sm-down:mx-auto
    md:-ml-24 md:-mt-6">
      <img :style="screenOneStyle" 
        src="@img/phone-bg@2x.png" 
        width="536" height="600" 
        alt="" 
        class="md:max-w-none mx-auto">
    </div>
    <div class="
    absolute top-0 left-0 
    xs:mt-24
    sm:mt-32
    md:-ml-36">
      <img :style="screenTwoStyle" 
        src="@img/screen@2x.png" 
        width="402" height="345" 
        alt="" 
        class="xs:w-60 sm-only:w-72 md:max-w-none">
    </div>
  </div> 
</template>

<script>
import HasGlider from '@/mixins/hasGlider';

export default {
  mixins: [HasGlider],
  computed: {
    screenOneStyle() {
      const distanceX = 30 * this.xPos * -1;
      const distanceY = 20 * this.yPos;
      return {
        transform: `translateX(${distanceX}px) translateY(${distanceY}px)`
      }
    },
    screenTwoStyle() {
      const distanceX = 20 * this.xPos;
      const distanceY = 10 * this.yPos * -1;
      return {
        transform: `translateX(${distanceX}px) translateY(${distanceY}px)`
      }
    }
  }
}
</script>
