<template>
  <div class="relative">
    <div class="absolute top-0 right-0 
      -mt-32  -mr-64">
      <img :style="screenOneStyle" 
        src="@img/hero-002@2x.png" 
        width="537" height="634" 
        alt="" 
        class="md:max-w-none">
    </div>
    <div class="absolute top-0 left-0 
      -mt-24 -ml-44">
      <img :style="screenTwoStyle" 
        src="@img/hero-001@2x.png" 
        width="644" 
        height="673" 
        alt=""
        class="md:max-w-none">
    </div>
    <div class="absolute bottom-0 left-0 
      -ml-16">
      <img :style="screenOneStyle" 
        src="@img/autoxkm@2x.png" 
        width="290" 
        height="140" 
        alt=""
        class="md:max-w-none">
    </div>
  </div> 
</template>

<script>
import HasGlider from '@/mixins/hasGlider';

export default {
  mixins: [HasGlider],
  computed: {
    screenOneStyle() {
      const distanceX = 30 * this.xPos * -1;
      const distanceY = 20 * this.yPos;
      return {
        transform: `translateX(${distanceX}px) translateY(${distanceY}px)`
      }
    },
    screenTwoStyle() {
      const distanceX = 20 * this.xPos;
      const distanceY = 10 * this.yPos;
      return {
        transform: `translateX(${distanceX}px) translateY(${distanceY}px)`
      }
    }
  }
}
</script>
