<template>
  <div class="relative pt-6" :class="{error: errors.has(name)}">
    <label :for="id" v-if="label" class="top-0 px-6 absolute leading-tight text-gray-80 bg-transparent font-inherit transition-all duration-75" :class="labelClass">{{label}}</label>
    <component :is="component" :class="fieldClass" class="resize-none py-4 px-6 block leading-none w-full bg-gray-20 border rounded-xl text-gray-80 p-0 font-inherit outline-none" :id="id" :name="name" :type="type" @input="onChange" @focus="onFocus" @blur="onBlur">
      <slot></slot>
    </component>
    <div class="-mb-4 absolute bottom-0 left-0 px-6 text-error-100 text-2xs" v-if="errors.has(name)">{{errors.first(name)}}</div>
  </div>
</template>

<script>
  export default {
    inject: ['$validator'],
    props: {
      id: String,
      label: String,
      name: String,
      value: String,
      type: {
        type: String,
        default: 'text'
      }
    },
    watch: {
      value(value, old) {
        this.model = value.substr(0);
      }
    },
    data() {
      return {
        model: this.value.substr(0),
        focussed: false
      };
    },
    computed: {
      fieldClass() {
        return !this.errors.has(this.name) ? 'border-gray-70' : ' border-error-100';
      },
      labelClass() {
        return this.focussed ? 'text-xs mt-0' : 'mt-10 text-gray-500';
      },
      component() {
        return this.type === 'textarea' ? 'textarea' : 'input';
      }
    },
    methods: {
      onChange(event) {
        this.$emit('input', event.target.value)
      },
      onBlur() {
        if (!this.value) {
          this.focussed = false;
        }
      },
      onFocus() {
        this.focussed = true;
      }
    }
  }
</script>
